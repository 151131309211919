<template>
	<div>
		<div class="bg flex f-d-c f-ai-c">
			<div class="bg-ic flex f-ai-c">
				<i></i>
				{{
					prescriptionInfo.status === 1 ? '待支付' :
					prescriptionInfo.status === 2 ? '待收货' :
					prescriptionInfo.status === 3 ? '已完成' :
					prescriptionInfo.status === 4 ? '已取消' :
					prescriptionInfo.status === 5 ? '待发货' :
					prescriptionInfo.status === 6 ? '已退款' :
					'未知状态'
				}}
			</div>
			<div class="express flex f-ai-c" v-if="[2, 3].indexOf(prescriptionInfo.status) > -1" @click="toExpress">
				<span>查看物流状态</span>
				<i></i>
			</div>
		</div>
		<div class="wrap">
			<div class="item one">
				<div>
					<div class="top flex f-ai-c">
						<i></i>
						{{addressInfo.name}}  {{addressInfo.mobilePhone}}
					</div>
					<span>{{addressInfo.address}}</span>
				</div>
			</div>
			<div class="item two">
				<div class="top">
					<span>用药人信息</span>
					<b>{{patientInfo.name}}  {{patientInfo.gender === 1 ? '男' : '女'}}  {{patientInfo.age}}岁</b>
				</div>
				<div class="center">{{prescriptionInfo._rp}}</div>
				<div class="bottom">共{{prescriptionInfo.jiliang}}剂，每日{{prescriptionInfo.jlPerDay}}剂，1剂分{{prescriptionInfo.times}}次服用</div>
			</div>
			<div class="order radius mt-8">
				预约单号：{{prescriptionInfo.orderId}}
			</div>
			<div class="fy radius mt-8">
				<div class="flex f-jc-sb" v-if="prescriptionInfo.outpatientCare">
					<span>诊费</span>
					<i>￥ {{prescriptionInfo.outpatientCare}}</i>
				</div>
				<div class="flex f-jc-sb">
					<span>药费</span>
					<i>￥ {{prescriptionInfo.medicinePrice}}</i>
				</div>
				<div class="flex f-jc-sb">
					<span>加工费</span>
					<i>￥ {{prescriptionInfo.processingFee}}</i>
				</div>
				<div class="flex f-jc-sb" v-if="prescriptionInfo.logisticsFee">
					<span>运费</span>
					<i>￥ {{prescriptionInfo.logisticsFee}}</i>
				</div>
				<div class="border-line" />
				<div class="flex-end">
					<div class="total-fee flex f-jc-sb f-ai-c">
						总计：
						<span>
							￥<i>{{(prescriptionInfo.totalFee).toString().split('.')[0]}}</i>
							<i v-if="(prescriptionInfo.totalFee).toString().split('.')[1]">.{{(prescriptionInfo.totalFee).toString().split('.')[1]}}</i>
						</span>
					</div>
				</div>
			</div>
			<div class="pay flex f-jc-sb f-ai-c" v-if="prescriptionInfo.status === 1">
				<div class="cancel" @click="clickCancel">
					<span>取消</span>
				</div>
				<div class="determine" @click="clickShowModal(1)">
					<span>去支付</span>
				</div>
			</div>
			<van-popup v-model="showModal" round >
				<div class="pop-content">
					<div class="pop-title">
						<span>费用明细</span>
					</div>
					<div v-if="prescriptionInfo.outpatientCare">
						<span>诊费</span>
						<label>￥{{prescriptionInfo.outpatientCare}}</label>
					</div>
					<div>
						<span>药费</span>
						<label>￥{{prescriptionInfo.medicinePrice}}</label>
					</div>
					<div>
						<span>加工费</span>
						<label>￥{{prescriptionInfo.processingFee}}</label>
					</div>
					<div class="flex f-jc-sb" v-if="prescriptionInfo.logisticsFee">
						<span>运费</span>
						<i>￥ {{prescriptionInfo.logisticsFee}}</i>
					</div>
					<div class="title2">
						<span>总计</span>
						<label class="red">￥{{prescriptionInfo.totalFee}}</label>
					</div>
					<!-- <div class="title2">
						<span>货到付款</span>
						<label class="red">货到付款</label>
					</div> -->
					<p>为您配药后，药品不可他用，订单不可取消，请您支付前仔细核对订单信息。</p>
					<div class="bottom">
						<div class="close" @click="clickShowModal(2)">
							<span>关闭</span>
						</div>
						<div class="topay" @click="clickShowModal(3)">
							<span>确认支付</span>
						</div>
					</div>
				</div>
			</van-popup>
		</div>
		<van-popup v-model="showCancelOrder" class="flex f-ai-c back-dialog">
			<h1>提示</h1>
			<span>是否确认取消该订单？</span>
			<div class="back-btns">
				<span @click="closeCancelDialog(1)">确定</span>
				<span @click="closeCancelDialog()">我再想想</span>
			</div>
		</van-popup>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { appointmentDetail, appointmentCancel } from '@/request/api/user';
import { ceil } from '@/utils';
export default {
	data () {
		return {
			showModal: false,
			showCancelOrder: false,
			addressInfo: {
				address: '',
				cityName: '',
				countyName: '',
				mobilePhone: '',
				name: '',
				provenceName: '',
				userId: 0,
				id: 0
			},
			patientInfo: {},
			prescriptionInfo: {
				totalFee: 0
			}
		};
	},
	computed: {
		...mapGetters({
			addressData: 'getAddressInfo'
		})
	},
	created () {
		this.setPageData();
	},
	mounted () {
		// if (window.history && window.history.pushState) {
		// history.pushState(null, null, document.URL);
		// window.addEventListener('popstate', this.onBack, false);
		// }
		window.addEventListener('popstate', this.onBack, false);
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	methods: {
		onBack () {
			this.$root.go('/user-center');
		},
		setPageData () {
			appointmentDetail({ id: this.$route.query.id }).then(data => {
				// console.log('data======', data);
				if (data && data === 'retry') {
					this.setPageData();
				} else if (data) {
					this.addressInfo = { ...data.address };
					this.patientInfo = { ...data.patient };
					const _rp = [];
					data.prescription.rp = JSON.parse(data.prescription.rp);
					data.prescription.rp.forEach(r => {
						let name = `${r.name}`;
						if (data.prescription.status === 2 || data.prescription.status === 3 || data.prescription.status === 5 || (data.prescription.status === 1 && data.payFirst === 0)) {
							name += `${r.count}${r.unit}`;
						}
						_rp.push(`${name}`); // .split('/')[1]
					});
					data.prescription._rp = _rp.join(' ');
					data.prescription.totalFee = ceil(+data.prescription.totalFee, 2)
					this.prescriptionInfo = { ...data.prescription };
				}
			});
		},
		clickShowModal (type) {
			if (type === 1) {
				this.showModal = true;
			} else if (type === 2) {
				this.showModal = false;
			} else if (type === 3) {
				this.gotoPay();
			}
		},
		closeCancelDialog (type) {
			if (type) {
				appointmentCancel({
					id: this.$route.query.id
				}).then(data => {
					if (data && data === 'retry') {
						this.clickCancel();
					} else if (data && data.ok === 1) {
						this.prescriptionInfo.status = 4;
					}
				});
			}
			this.showCancelOrder = false;
		},
		clickCancel () {
			this.showCancelOrder = true;
		},
		gotoPay () {
			// this.showModal = false;
			this.$root.go('/payment/', {
				orderId: this.prescriptionInfo.orderId,
				type: 1,
				totalFee: this.prescriptionInfo.totalFee,
				appointmentId: this.$route.query.id
			});
			// setTimeout(() => {
			// window.location.reload();
			// }, 100);
		},
		toExpress () {
			this.$root.go('/express-detail', {
				appointmentId: this.prescriptionInfo.id
			});
		}
	}
};
</script>
<style lang="less" scoped>
	.bg {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		margin-top: -112px;
		height: 150px;
		background: url('../../assets/images/consultation.png') no-repeat;
		background-color: #ff7b4f;
		color: #FFFFFF;
		&-ic {
			font-size: 19px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			margin-top: 33px;
			height: 18px;
			i {
				display: inline-block;
				background: url('../../assets/images/icon2.png') no-repeat;
				background-size: contain;
				width: 17px;
				height: 17px;
				margin-right: 4px;
			}
		}
		.express {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			margin-top: 16px;
			i {
				display: inline-block;
				width: 5px;
				height: 9px;
				margin-left: 6px;
				background: url('../../assets/images/right-arrow-white.png') no-repeat;
				background-size: contain;
			}
		}
	}
	.wrap {
		z-index: 2;
		margin-top: 112px;
		position: relative;
		z-index: 1;
		.item {
			height: 89.5px;
			background: #FFFFFF;
			box-shadow: 0px 4.5px 18px -4px rgba(16, 31, 46, 0.04);
			border-radius: 8px;
			box-sizing: border-box;
			margin-bottom: 10px;
		}
		.one {
			padding: 20px 10px 25px 15px;
			.top {
				margin-bottom: 8px;
				i {
					display: inline-block;
					background: url('../../assets/images/icon.png') no-repeat;
					background-size: contain;
					width: 17px;
					height: 17px;
					margin-right: 4px;
				}
				font-size: 17px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #232830;
			}
			span {
				font-size: 13px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #7D7D7D;
				margin-left: 24px;
			}
			.right-arrow {
				display: flex;
				align-items: center;
				span {
					font-size: 20px;
				}
			}
		}
		.two {
			padding: 15px 15px 20px;
			height: auto;
			.top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #F3F3F3;
				padding-bottom: 14px;
				span {
					font-size: 12px;
					font-weight: 500;
					color: #7D7D7D;
					line-height: 12px;
				}
				b {
					font-size: 13px;
					font-weight: 500;
					color: #232830;
					line-height: 13px;
				}
			}
			.center {
				font-size: 13px;
				font-weight: 400;
				color: #3B3D40;
				line-height: 20px;
				margin-top: 14px;
			}
			.bottom {
				font-size: 12px;
				font-weight: 400;
				color: #7D7D7D;
				line-height: 12px;
				margin-top: 14px;
			}
		}
	}
	.radius {
		background: #FFFFFF;
		box-shadow: 0px 4.5px 18px -4px rgba(16, 31, 46, 0.04);
		border-radius: 8px;
		border: .5px solid #EFEFEF;
		width: 100%;
	}
	.mt-8 {
		margin-top: 8px;
	}
	.order {
		height: 40px;
		line-height: 40px;
		font-size: 12px;
		font-weight: 400;
		color: #232830;
		padding-left: 15px;
		box-sizing: border-box;
	}
	.fy {
		margin-bottom: 82px;
		padding: 20px 15px 6px 15px;
		box-sizing: border-box;
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #232830;
		}
		i {
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #F33D3A;
			margin-bottom: 14px;
		}
	}
	.pay {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background: #FFFFFF;
		height: 52px;
		padding: 0 12px;
		span {
			font-size: 20px;
			font-family: PingFangSC-Medium, PingFang SC;
			color: #F33D3A;
		}
		i {
			&:nth-child(2) {
				font-size: 12px;
			}
		}
	}
	.border-line {
		border-bottom: 1px solid #F3F3F3;
	}
	.flex-end {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-size: 12px;
		font-weight: 400;
		color: #232830;
		line-height: 12px;
	}
	.total-fee {
		position: relative;
		background: #FFFFFF;
		height: 52px;
		padding: 0;
		span {
			font-size: 20px;
			font-family: PingFangSC-Medium, PingFang SC;
			color: #F33D3A;
		}
		i {
			margin-bottom: 0;
			&:nth-child(2) {
				font-size: 12px;
			}
		}
	}
	.pay {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background: #FFFFFF;
		height: 52px;
		padding: 0 15px;
		display: flex;
		justify-content: space-between;
		span {
			font-size: 20px;
			font-family: PingFangSC-Medium, PingFang SC;
			color: #F33D3A;
		}
		i {
			&:nth-child(2) {
				font-size: 12px;
			}
		}
	}
	.cancel {
		background: #FFFFFF;
		border-radius: 20px;
		border: 1px solid #FF6450;
		padding: 13px 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		span {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #FF6450;
			line-height: 13px;
			white-space: nowrap;
		}
	}
	.determine {
		background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
		border-radius: 20px;
		padding: 14px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 10px;
		margin-right: 0;
		flex: 1;
		span {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 13px;
			white-space: nowrap;
		}
	}
	.pop-content {
		padding: 29px 19px 24px 19px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-width: 240px;
		p {
			font-size: 12px;
			font-weight: 400;
			color: #8B8B8B;
			line-height: 17px;
			margin-top: 21px;
		}
		div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 13px;
			font-weight: 400;
			color: #7D7D7D;
			line-height: 13px;
			width: 100%;
			margin-top: 14px;
			.red {
				color: #F33D3A;
			}
		}
		div.title2, label {
			color: #3B3D40;
		}
		div.pop-title {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 0;
			span {
				font-size: 15px;
				font-weight: 500;
				color: #232830;
				line-height: 15px;
			}
		}
		div.bottom {
			margin-top: 29px;
			display: flex;
			align-items: center;
			justify-content: space-around;
			.close {
				background: #FFFFFF;
				border-radius: 16px;
				border: 1px solid #FF6450;
				width: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 1;
				padding: 9px 0;
				span {
					font-size: 13px;
					font-weight: 400;
					color: #FF6450;
					line-height: 13px;
					white-space: nowrap;
				}
			}
			.topay {
				background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
				border-radius: 16px;
				width: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 1;
				margin-left: 15px;
				padding: 11px 0;
				span {
					font-size: 13px;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 13px;
					white-space: nowrap;
				}
			}
		}
	}
	.back-dialog {
		flex-direction: column;
		padding: 30px 25px 25px 25px;
		width: 290px;
		height: 120px;
		background: #FFFFFF;
		border-radius: 7px;
		justify-content: space-between;
		h1 {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #232830;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #3B3D40;
			text-align: center;
		}
		.back-btns {
			span {
				display: inline-block;
				width: 112px;
				height: 33px;
				background: #FFFFFF;
				border-radius: 16.5px;
				border: 1px solid #FF6450;
				line-height: 33px;
				margin-top: 20px;
				&:nth-child(1) {
					color: #FF6450;
					margin-right: 16px;
				}
				&:nth-child(2) {
					color: #FFFFFF;
					background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
				}
			}
		}
	}
</style>